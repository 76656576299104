// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom


jQuery(document).ready(function () {
    jQuery(".herosilder").slick({
        dots:false,
        infinite: true,
        arrows:false,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll:1,
        autoplaySpeed: 6000,
    });

    jQuery(".style-section blockquote .gca-column.one-half").find("img").parent().parent().addClass("image-text-style");
    jQuery(".style-section blockquote .full-container").parent().addClass("full-container-section");
});
